<template>
  <div class="position-mgt">
    <van-nav-bar title="职位管理" />
    <template v-if="havePostion === 2">
      <div class="no-position-con">
        <div class="no-position">
          <img src="../../assets/positionMgt/no_position.png" alt />
          <p>您还未发布任何职位，快来发布职位吧～</p>
          <div class="go-position-box">
            <van-button type="info" round block @click="addPosition">发布职位</van-button>
          </div>
        </div>
      </div>
    </template>
    <template v-if="havePostion === 1">
      <div class="handle-box">
        <div class="dropdown-box">
          <div class="dropdown-btn">
            <span>筛选</span>
            <img class="up" v-if="dropDownShow" src="../../assets/positionMgt/up.png" alt />
            <img class="down" v-else src="../../assets/positionMgt/down.png" alt />
          </div>
          <van-dropdown-menu class="dropdown" @click="dropDown" active-color="#206cfe">
            <van-dropdown-item v-model="value" :options="option" ref="mydropdown" />
          </van-dropdown-menu>
          <div class="disabled-box"></div>
        </div>
        <span class="add" @click="addPosition">
          <img src="../../assets/common/add.png" alt />
          发布职位
        </span>
      </div>
      <div class="list-box">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
          <van-cell v-for="(item, i) in list" :key="i">
            <div class="list" @click="goPositionDetail(item)">
              <div class="list-base">
                <span>{{ item.name }}</span>
                <i>{{ item.salaryRangeName }}</i>
              </div>
              <div class="list-label">
                <span>{{ item.city }}</span>
                <span>{{ item.workExperienceName }}</span>
                <span>{{ item.qualificationsName }}</span>
              </div>
              <div class="list-view">
                <span>
                  浏览
                  <i>{{ item.browseNum }}次</i>
                </span>
                <em></em>
                <span>
                  投递
                  <i>{{ item.deliveryCount }}次</i>
                </span>
              </div>
              <div class="list-status">
                <span>创建于{{ item.createdTime | timeFilter }}</span>
                <span>
                  <img :src="require(`../../assets/positionMgt/${item.state}.png`)" alt />
                  <i
                    :class="{ waiting: item.state === 'APPROVE_NORMAL', error: item.state === 'APPROVE_NOT_PASS' }"
                  >
                    {{
                    item.stateDesc
                    }}
                  </i>
                </span>
              </div>
              <div
                class="reason"
                v-if="item.state === 'APPROVE_NOT_PASS'"
              >未通过原因：{{ item.approveReason || '-' }}</div>
            </div>
          </van-cell>
        </van-list>
      </div>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'ntq',
  data() {
    return {
      value: '',
      option: [],
      isEmail: true,
      isLegalize: false,
      list: [],
      loading: false,
      finished: false,
      havePostion: 0,
      query: {
        pageIndex: 1,
        pageSize: 10,
      },
      dropDownShow: false,
    };
  },
  filters: {
    timeFilter(data) {
      return moment(new Date(data)).format('YYYY.MM.DD h:mm');
    },
  },
  watch: {
    value: {
      handler: function (N, O) {
        if (N !== O) {
          this.getPositionList();
        }
      },
    },
  },
  mounted() {
    this.$http
      .get('/selfhelpOfficialAccounts/positionSelfhelp/allPositions')
      .then((res) => {
        if (res.code === 200) {
          this.havePostion = res.data.length ? 1 : 2;
          this.getApproveState();
          if (localStorage.getItem('userInfo')) {
            this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
          }
        }
      });
  },
  methods: {
    getApproveState() {
      this.$http
        .get('/selfhelpOfficialAccounts/positionSelfhelp/approveState')
        .then((res) => {
          if (res.code === 200) {
            this.option = [{ text: '全部职位', value: '' }].concat(
              res.data.map((el) => {
                el.text = el.name;
                el.value = el.code;
                return el;
              })
            );
          }
        });
    },
    dropDown() {
      this.dropDownShow = !this.dropDownShow;
      // this.$refs.mydropdown.toggle();
    },
    onLoad() {
      this.getPositionList(false);
    },
    getPositionList(isFirst = true) {
      if (isFirst) {
        this.list = [];
        this.loading = false;
        this.finished = false;
        this.query = {
          pageIndex: 1,
          pageSize: 10,
        };
      }
      this.loading = true;
      this.$http
        .get('/selfhelpOfficialAccounts/positionSelfhelp', {
          ...this.query,
          companyId: this.userInfo.companyVO.id,
          approveStatus: this.value,
        })
        .then((res) => {
          if (res.code === 200) {
            this.loading = false;
            this.loaded = true;
            this.list = this.list.concat(res.data);
            if (this.list.length >= res.pagination.totalRow) {
              this.finished = true;
            } else {
              this.query.pageIndex++;
            }
          }
        });
    },
    addPosition() {
      this.$router.push('/positionMgt/positionAddOrEdit');
    },
    goPositionDetail(data) {
      this.$router.push({
        path: '/positionMgt/positionDetail',
        query: { positionId: data.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.position-mgt {
  height: 100%;
  width: 100%;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  .no-position-con {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    .no-position {
      width: 276px;
      height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        height: 170px;
        width: 170px;
      }
      p {
        margin-top: 8px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        text-align: center;
      }
      .go-position-box {
        width: 100%;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        padding: 0 46px;
      }
    }
  }
  .handle-box {
    height: 44px;
    line-height: 44px;
    background: #fff;
    display: flex;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    padding: 0 16px;
    .dropdown-box {
      flex: 1;
      display: flex;
      position: relative;
      ::v-deep .van-dropdown-menu__title::after {
        display: none;
      }
      .dropdown {
        position: absolute;
        width: 180px;
        height: 44px;
        background: rgba(220, 38, 38, 0);
        line-height: 44px;
      }
      .dropdown-btn {
        display: flex;
        align-items: center;
        position: absolute;
        left: 140px;
        top: 0;
        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-right: 5px;
        }
        img {
          width: 9px;
          height: 5px;
        }
      }
      .disabled-box {
        position: absolute;
        left: 0;
        top: 0;
        width: 140px;
        height: 44px;
      }
    }
    .add {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 44px;
      img {
        height: 13px;
        width: 13px;
        margin-right: 5px;
      }
      span {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
      }
    }
  }
  .list-box {
    i {
      font-style: normal;
    }
    flex: 1;
    overflow-y: auto;
    padding: 15px;
    .van-cell {
      padding: 0;
      margin-bottom: 10px;
      border-radius: 4px;
    }
    .list {
      padding: 15px;
      background: #ffffff;
      border-radius: 4px;
      .list-base {
        display: flex;
        justify-content: space-between;
        span {
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
        }
        i {
          font-family: DINAlternate-Bold, DINAlternate;
          font-weight: bold;
          font-size: 18px;
          color: #f44336;
        }
      }
      .list-label {
        margin: 10px 0;
        span {
          background: #f7f7f7;
          border-radius: 2px;
          padding: 0 4px;
          margin-right: 5px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
      }
      .list-view {
        padding-bottom: 15px;
        border-bottom: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          i {
            color: #333333;
          }
        }
        em {
          margin: 0 10px;
          height: 6px;
          width: 1px;
          display: inline-block;
          background: #cccccc;
        }
      }
      .list-status {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          display: flex;
          align-items: center;
          img {
            width: 12px;
            height: 12px;
            margin-right: 5px;
          }
        }
        .waiting {
          color: #206cfe;
        }
        .error {
          color: #f44336;
        }
      }
      .reason {
        margin-top: 10px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #f44336;
      }
    }
  }
}

::v-deep .van-dropdown-menu__bar {
  height: 44px;
  background: rgba(220, 38, 38, 0);
  box-shadow: none;
  .van-dropdown-menu__item {
    padding-right: 40px;
  }
}
::v-deep .van-dropdown-item__content {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px 20px 16px;
  .van-dropdown-item__option {
    width: auto;
    padding: 0;
    margin: 10px 10px 0 0;
    .van-cell__title {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      padding: 5px 8px;
      background: #f7f7f7;
      border-radius: 2px;
    }
    .van-cell__value {
      display: none;
    }
  }
  .van-dropdown-item__option--active {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    .van-cell__title {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #206cfe;
      background: #e8f0fe;
      padding: 5px 8px;
      border-radius: 2px;
    }
    .van-cell__value {
      display: block;
      background-image: url(../../assets/release/checked.png);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 12px;
      position: absolute;
      height: 12px;
      width: 12px;
      top: 0;
      right: 0;
      .van-icon:before {
        display: none;
      }
    }
  }
}
</style>
